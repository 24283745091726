/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import useSearch from "@whitespace/gatsby-plugin-search/src/hooks/useSearch";
import { useEffect } from "react";

export default function SearchTracker() {
  const searchContext = useSearch();
  const {
    params: { sortBy, sortOrder, from, size, ...params },
    totalHits,
  } = searchContext;
  void sortBy;
  void sortOrder;
  void from;
  void size;

  useEffect(() => {
    if (totalHits != null && typeof window !== "undefined") {
      window._paq.push(["trackSiteSearch", params.query, false, totalHits]);
    }
  }, [JSON.stringify(params), totalHits]);

  return null;
}
